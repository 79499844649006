import React, { useRef } from "react";
import {
  motion,
  useMotionTemplate,
  useMotionValue,
  useSpring,
} from "framer-motion";
import { FiMousePointer } from "react-icons/fi";

const Example = () => {
  return (
    <div className="grid w-full justify-items-center px-4 py-12 text-slate-900">
    <TiltCard />
  </div>
  
  );
};

const ROTATION_RANGE = 32.5;
const HALF_ROTATION_RANGE = 32.5 / 2;

const TiltCard = () => {
  const ref = useRef(null);

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  const xSpring = useSpring(x);
  const ySpring = useSpring(y);

  const transform = useMotionTemplate`rotateX(${xSpring}deg) rotateY(${ySpring}deg)`;

  const handleMouseMove = (e) => {
    if (!ref.current) return [0, 0];

    const rect = ref.current.getBoundingClientRect();

    const width = rect.width;
    const height = rect.height;

    const mouseX = (e.clientX - rect.left) * ROTATION_RANGE;
    const mouseY = (e.clientY - rect.top) * ROTATION_RANGE;

    const rX = (mouseY / height - HALF_ROTATION_RANGE) * -1;
    const rY = mouseX / width - HALF_ROTATION_RANGE;

    x.set(rX);
    y.set(rY);
  };

  const handleMouseLeave = () => {
    x.set(0);
    y.set(0);
  };

  return (
    <div className="flex flex-wrap gap-20 mobile-size">
    <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-3">
      <motion.div
        ref={ref}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          transformStyle: "preserve-3d",
          transform,
        }}
        className="relative h-64 w-52 rounded-xl bg-gradient-to-br from-indigo-300 to-violet-300"
      >
        <div
          style={{
            transform: "translateZ(75px)",
            transformStyle: "preserve-3d",
          }}
          className="absolute inset-4 grid place-content-center rounded-xl bg-white shadow-lg"
        >
          <FiMousePointer
            style={{
              transform: "translateZ(75px)",
            }}
            className="mx-auto text-4xl"
          />
          <p
            style={{
              transform: "translateZ(50px)",
              fontFamily: 'Montserrat',
              fontWeight:400
            }}
            className="text-center text-l font-bold"
           
          >
            Mobile App Development
          </p>
        </div>
      </motion.div>
    </div>
    <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-3">
      <motion.div
        ref={ref}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          transformStyle: "preserve-3d",
          transform,
        }}
        className="relative h-64 w-52 rounded-xl bg-gradient-to-br from-indigo-300 to-violet-300"
      >
        <div
          style={{
            transform: "translateZ(75px)",
            transformStyle: "preserve-3d",
          }}
          className="absolute inset-4 grid place-content-center rounded-xl bg-white shadow-lg"
        >
          <FiMousePointer
            style={{
              transform: "translateZ(75px)",
            }}
            className="mx-auto text-4xl"
          />
          <p
           style={{
            transform: "translateZ(50px)",
            fontFamily: 'Montserrat',
            fontWeight:400
          }}
            className="text-center text-l font-bold"
          >
            Web App Development
          </p>
        </div>
      </motion.div>
    </div>
    <div className="w-full sm:w-1/2 md:w-1/4 lg:w-1/4 xl:w-1/4 mb-3">
      <motion.div
        ref={ref}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        style={{
          transformStyle: "preserve-3d",
          transform,
        }}
        className="relative h-64 w-52 rounded-xl bg-gradient-to-br from-indigo-300 to-violet-300"
      >
        <div
          style={{
            transform: "translateZ(75px)",
            transformStyle: "preserve-3d",
          }}
          className="absolute inset-4 grid place-content-center rounded-xl bg-white shadow-lg"
        >
          <FiMousePointer
            style={{
              transform: "translateZ(75px)",
            }}
            className="mx-auto text-4xl"
          />
          <p
           style={{
            transform: "translateZ(50px)",
            fontFamily: 'Montserrat',
            fontWeight:400
          }}
            className="text-center text-l font-bold"
          >
           Testing
          </p>
        </div>
      </motion.div>
    </div>
    <div></div>
  </div>
  
  );
};

export default Example;