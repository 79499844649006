import { Image } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import { motion, useInView } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { FiDollarSign, FiEye, FiPlay, FiSearch ,FiFeather} from "react-icons/fi";
import TechArchitecture from './techArchitecture.json';
import Frontend from './Frontend.json';
import BackEndAnimation from './backendAnimation.json'
import Testing from './testAnddevops.json'
import Launch from './launch.json'
import Lottie from 'react-lottie';

import { Stars } from "@react-three/drei";
import { FiArrowRight } from "react-icons/fi";
import {
  useMotionTemplate,
  useMotionValue,
  animate,
} from "framer-motion";
import React from "react";
import { styled } from "styled-components";



const SwapColumnFeatures = () => {
  const [featureInView, setFeatureInView] = useState(features[0]);

  return (
    <section className="relative mx-auto max-w-7xl">
      <SlidingFeatureDisplay featureInView={featureInView} />

      {/* Offsets the height of SlidingFeatureDisplay so that it renders on top of Content to start */}
      <div className="-mt-[100vh] hidden md:block" />

      {features.map((s) => (
        <Content
          key={s.id}
          featureInView={s}
          setFeatureInView={setFeatureInView}
          {...s}
        />
      ))}
    </section>
  );
};

const SlidingFeatureDisplay = ({ featureInView }) => {
  return (
    <div
      style={{
        justifyContent:
          featureInView.contentPosition === "l" ? "flex-end" : "flex-start",
      }}
      className="pointer-events-none sticky top-0 z-10 hidden h-screen w-full items-center justify-center md:flex"
    >
      <motion.div
        layout
        transition={{
          type: "spring",
          stiffness: 400,
          damping: 25,
        }}
        className="h-fit w-3/5 rounded-xl p-8"
      >
        <ExampleFeature featureInView={featureInView} />
      </motion.div>
    </div>
  );
};

const Content = ({ setFeatureInView, featureInView }) => {
  const ref = useRef(null);
  const isInView = useInView(ref, {
    margin: "-150px",
  });

  useEffect(() => {
    if (isInView) {
      setFeatureInView(featureInView);
    }
  }, [isInView]);

  return (
    <section
      ref={ref}
      className="relative z-0 flex h-fit md:h-screen"
      style={{
        justifyContent:
          featureInView.contentPosition === "l" ? "flex-start" : "flex-end",
      }}
    >
      <div className="grid h-full w-full place-content-center px-4 py-12 md:w-2/5 md:px-8 md:py-8">
        <motion.div
          initial={{ opacity: 0, y: 25 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
        >
          
          <p className="my-3 text-3xl sm:text-4xl md:text-5xl font-bold" style={{fontFamily: "Montserrat"}}>{featureInView.title}</p>
          <p className="text-slate-600" style={{fontFamily: "Montserrat",color:'white'}}>{featureInView.description}</p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 25 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, ease: "easeInOut" }}
          className="mt-8 block md:hidden"
        >
          <ExampleFeature featureInView={featureInView} />
        </motion.div>
      </div>
    </section>
  );
};

const ExampleFeature = ({ featureInView }) => {

  const decide  = () =>{
    if(featureInView.title == 'Technology Architecture'){
      return(
      //   <div className="relative h-96 w-full rounded-xl bg-slate-800 shadow-xl">
      //   <div className="flex w-full gap-1.5 rounded-t-xl bg-slate-900 p-3">
      //     <div className="h-3 w-3 rounded-full bg-red-500" />
      //     <div className="h-3 w-3 rounded-full bg-yellow-500" />
      //     <div className="h-3 w-3 rounded-full bg-green-500" />
      //   </div>
      //   <div className="p-2">
      //     <p className="font-mono text-sm text-slate-200">
      //       <span className="text-green-300">~</span> Show a part of your product
      //       that explains what{" "}
      //       <span className="inline-block rounded bg-indigo-600 px-1 font-semibold">
      //         "{featureInView.title}"
      //       </span>{" "}
      //       means.
      //     </p>
      //   </div>
  
      //   <span className="absolute left-[50%] top-[50%] -translate-x-[50%] -translate-y-[50%] text-9xl text-slate-700">
      //     <featureInView.Icon />
      //   </span>
      // </div>
      <Lottie options={{
        loop: true,
        autoplay: true,
        animationData: TechArchitecture,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }} className="relative z-0 flex h-fit md:h-screen"/>
      )
    }else if(featureInView.title == 'Frontend Development'){
      return(
        // <div style={{display:'flex',flexDirection:'row',position:'absolute'}} className="relative z-0 flex h-fit md:h-screen">
        <Lottie options={{
          loop: true,
          autoplay: true,
          animationData: Frontend,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }} className="relative z-0 flex h-fit md:h-screen" />
       
        
      )
    }else if(featureInView.title == 'Backend Development'){
     return(
     
      <Lottie 
      
      options={{
        loop: true,
        autoplay: true,
        animationData: BackEndAnimation,
        rendererSettings: {
          preserveAspectRatio: 'xMidYMid slice'
        }
      }}  />
     
     )
      
    }else if(featureInView.title == 'Testing & Devops'){
      return(
        <Lottie options={{
          loop: true,
          autoplay: true,
          animationData: Testing,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }} className="relative z-0 flex h-fit md:h-screen" />
      )
    }else{
      return(
        <Lottie options={{
          loop: true,
          autoplay: true,
          animationData: Launch,
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
          }
        }} className="relative z-0 flex h-fit md:h-screen" />
      )
    }
  }
 
  return decide()
};

export default SwapColumnFeatures;

const features = [
  {
    id: 1,
   
    title: "Technology Architecture",
    description:
      "We meticulously plan the structure of your app, ensuring a solid foundation for future growth and scalability. Our technical architects use advanced methodologies to create a robust framework.",
    contentPosition: "r",
    Icon: FiEye,
  },
  {
    id: 2,
   
    title: "Frontend Development",
    description:
    "Our front-end development team brings your designs to life, creating interactive and user-friendly interfaces. Utilizing the latest technologies like React, Angular, and Vue.js, we ensure seamless user experiences.",
    contentPosition: "l",
    Icon: FiDollarSign,
  },
  {
    id: 3,
   
    title: "Backend Development",
    description:
      "We develop the back-end infrastructure, ensuring your app is fast, secure, and scalable. Our developers work with cutting-edge technologies like Node, Python, and Ruby to build a strong and efficient backend.",
    contentPosition: "r",
    Icon: FiPlay,
  },
  {
    id: 4,
   
    title: "Testing & Devops",
    description:
      "Rigorous testing is conducted to eliminate bugs and ensure a smooth user experience. Our QA processes ensure your app is safe, reliable, and delivers the best performance.",
    contentPosition: "l",
    Icon: FiDollarSign,
  },{
    id: 5,
   
    title: "Launch & Deploy",
    description:
      "We launch your app with precision and provide ongoing support and maintenance to ensure what we built remains at its best. Our team monitors performance, addresses issues, and ensures your app runs smoothly.",
    contentPosition: "r",
    Icon: FiFeather,
  },
];