import { Stars } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React, { useEffect } from "react";

import {
  useMotionTemplate,
  useMotionValue,
  motion,
  animate,
} from "framer-motion";

const COLORS_TOP = ["#13FFAA", "#1E67C6", "#CE84CF", "#DD335C"];

export const AuroraHero = ({ children, ...props }) => {
  const color = useMotionValue(COLORS_TOP[0]);

  useEffect(() => {
    animate(color, COLORS_TOP, {
      ease: "easeInOut",
      duration: 10,
      repeat: Infinity,
      repeatType: "mirror",
    });
  }, []);

  const backgroundImage = useMotionTemplate`radial-gradient(125% 125% at 50% 0%, #020617 50%, ${color})`;

  return (
    <motion.section
      style={{
        backgroundImage,
        zIndex: -999,
      }}
      className="bg-gray-950 px-4 pt-24 text-gray-200"
      {...props}
    >
      <div className="absolute inset-0 z-0">
        <Canvas>
          <Stars radius={50} count={5000} factor={10} fade speed={2} />
        </Canvas>
      </div>
      <div className="relative z-10">
        {children}
      </div>
    </motion.section>
  );
};
