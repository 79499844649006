import logo from './logo.svg';
import './App.css';
import LandingScreen from './ParallaxZoom';

function App() {
  return (
    <div className="App">
      <LandingScreen/>
    </div>
  );
}

export default App;
