import "./LandingScreen.css";
import React, { useEffect, useRef, useState } from "react";
import Spline from "@splinetool/react-spline";
import { motion, useTransform, useScroll } from "framer-motion";
import SwapColumnFeatures from "./DevSpecsComp";
import { AuroraHero } from "./BackgroundAnime";
import emailjs from "emailjs-com";

import Example from "./Card";

import styled from 'styled-components';
const Container = styled.div`
  margin-top: 100px;
  margin-left: 50px;
  margin-right: 50px;

  @media (max-width: 767px) {
    margin-top: 50px;
    margin-left: 0px;
    margin-right: 0px;
  }
`;

const ProductContainer = styled.div`
margin-top:100;
@media (max-width: 767px) {
  margin-top:0px;
  margin-left:20px;
  margin-right:20px;
}
`

const VideoContainer = styled.div`
margin-top:100px;
@media (max-width: 767px) {
  margin-top:50px;
 
}
`
const LandingScreen = (props) => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const footerRef = useRef(null);

  const scrollToFooter = () => {
    footerRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_apexmails", "template_4jrg58c", e.target, "Xe_KOXS3X3TR_VbXp")
      .then(
        (result) => {
          setStatus("Email sent successfully!");
          setEmail("");
          setMessage("");
        },
        (error) => {
          setStatus("Failed to send email. Please try again.");
        }
      );
  };
  useEffect(() => {
    const videoElement = videoRef.current;

    if (!videoElement) return;

    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5, // 0.5 means when 50% of video is visible
    };

    const callback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          videoElement.play().catch((error) => {
            console.log("Autoplay prevented:", error);
          });
        } else {
          videoElement.pause();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    observer.observe(videoElement);

    return () => {
      observer.unobserve(videoElement);
      observer.disconnect();
    };
  }, []);

  const handleUnmute = () => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.muted = false;
      setIsMuted(false);
      videoElement.play();
    }
  };

  return (
    <div>
    <AuroraHero>
      <div >
        <div className="parallax">
          <div className="navbar bg-gray-900 text-white py-4 px-6 flex justify-between items-center">
  <div className="logo text-2xl font-bold">ApexWorks</div>
  <button onClick={scrollToFooter} style={{ background: 'linear-gradient(90deg, #e81123, #e30083)',}}   className="bg-purple-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition duration-300">
    Start Your Journey
  </button>
</div>
          <div className="title-overlay">
            <h1 className="title-text" >
            Your Dream, Our New Dawn
            </h1>
            <h3 className="description-text" >
            ApexWorks: Just dream it and watch us launch it
            </h3>
          </div>
          <div className="spline-background">
          <Spline
        scene="https://prod.spline.design/Ho1REMg3d7sx2Rdd/scene.splinecode" 
      />
          </div>
         
        </div>
        <div className="mobile-heading">
            <h1 className="text-xl md:text-3xl font-bold mb-2" style={{display:'block',fontSize:40,fontFamily: "Montserrat",textAlign:'center',lineHeight:1.5}}>
            Your Dream, Our New Dawn
            </h1>
            <h3 className="design-text text-sm md:text-base" style={{textAlign:'center',marginLeft:20}} >
            ApexWorks: Just dream it and watch us launch it
            </h3>
          </div>
        <Container

        >
          <div>
          <div className="flex flex-col md:flex-row md:items-center md:justify-between">
  <ProductContainer>
  <h2 className="design-title text-xl md:text-3xl font-bold mb-2">Transform Your Raw Ideas</h2>
    <p className="design-text text-sm md:text-base" style={{color:'white'}}>
      Leave all the worry to us as we turn your raw idea, be it mobile or web, into detailed, stunning designs. Our team crafts visually appealing and user-friendly interfaces, ensuring your vision is beautifully brought to life.
    </p>
  </ProductContainer>
</div>

            <VideoContainer>
              <video
                ref={videoRef}
                width="100%"
                muted={isMuted}
                loop
                // controls
                style={{ borderRadius: 10 }}
              >
                <source src="/design.mp4" type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              {isMuted && (
                <button
                  onClick={handleUnmute}
                  style={{ marginTop: "10px", display: "none" }}
                >
                  Unmute Video
                </button>
              )}
            </VideoContainer>
          </div>
        </Container>
        <div>
          <div style={{ marginTop: 100 }}>
            {/* <div className="pd-title">
              <p
                className="my-3 text-3xl sm:text-4xl md:text-5xl font-bold"
                style={{ fontFamily: "Montserrat" }}
              >
                Product Development
              </p>
              <p
                className="text-slate-600"
                style={{ fontFamily: "Montserrat" }}
              >
                Transforming your ideas into reality involves turning concepts into tangible solutions, especially in web and mobile app development. It begins with idea generation and refinement, followed by viability testing and potential analysis. Finally, through meticulous development, your vision is brought to life, delivering innovative solutions that exceed expectations and resonate with your audience.
              </p>
            </div> */}
              <div className="md:w-4/4 mx-30" style={{marginTop:100,marginLeft:'25px'}}>
              <p className="my-3 text-3xl sm:text-4xl md:text-5xl font-bold" style={{fontFamily: "Montserrat"}}>Product Development</p>
          <p className="text-slate-600" style={{fontFamily: "Montserrat",color:'white'}}>Transforming ideas into reality involves turning concepts into solutions, especially in web and mobile app development. It starts with idea generation, testing, and analysis. Meticulous development then brings your vision to life, delivering innovative solutions that exceed expectations.</p>
  </div>
            <SwapColumnFeatures />
          </div>
        </div>
      </div>
      <div >
    <div>
    <p className="my-3 text-5xl font-bold" style={{fontFamily: "Montserrat",textAlign:'center'}}>Our Services</p>
        
    </div>
   <div style={{display:'flex',justifyContent:'center'}}>
   <Example />
   </div>
</div>
    </AuroraHero>
    <div ref={footerRef} className="bg-gray-900 text-white py-10 px-4 sm:px-6 lg:px-8 fotter">
  <div className="max-w-2xl mx-auto text-center">
    <h3 className="text-3xl mb-3" style={{fontFamily: "Montserrat",fontWeight:'800'}}>Got an idea ??</h3>
    <p>Connect with us!!</p>
    <p className="mb-5" style={{fontFamily: "Montserrat",fontWeight:'400'}}>Phn: +91 7619181438, 6385770457, 9739029778</p>
    <form onSubmit={sendEmail} className="space-y-4">
      <input
        type="email"
        name="email"
        placeholder="Enter your email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        className="w-full p-2 sm:p-3 rounded-lg text-black border-2 border-transparent focus:outline-none focus:border-blue-500 transition duration-300"
        required
      />
      <textarea
        name="message"
        placeholder="Your message"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        className="w-full p-2 sm:p-3 rounded-lg text-black border-2 border-transparent focus:outline-none focus:border-blue-500 transition duration-300"
        required
      />
      <button
        style={{marginBottom:50}}
        type="submit"
        className="w-full bg-blue-500 text-white px-4 py-2 sm:py-3 rounded-lg hover:bg-blue-600 transition duration-300"
      >
        Send
      </button>
    </form>
    {status && <p className="mt-5">{status}</p>}
  </div>
</div>



    </div>
  );
};

export default LandingScreen;
